<template>
  <div :class="$style.container">
    <form @submit.prevent="submitForm">
      <h1>Редактировать акцию</h1>
      <input
        type="text"
        v-model="offer.title"
        placeholder="Заголовок"
        :class="$style.title"
      />
      <div v-if="this.errors.title" :class="$style.error">
        {{ this.errors.title }}
      </div>
      <ImageBulkUploader
        v-model="offer.image"
        :text="'Загрузить фото'"
        :class="$style.imageUploader"
      />
      <div :class="$style.photo">
        <img :src="s3_link + offer.image[0]" alt="Загрузить фото" />
      </div>
      <div v-if="this.errors.image" :class="$style.error">
        {{ this.errors.image }}
      </div>
      <div :class="$style.date">
        <label class="label">
          Начало Акции
          <input type="date" v-model="offer.beginningAt" />
          <br />
          <div v-if="this.errors.beginningAt" :class="$style.error">
            {{ this.errors.beginningAt }}
          </div>
        </label>
        <label class="label">
          Конец Акции
          <input type="date" v-model="offer.endAt" /> <br />
          <div v-if="this.errors.endAt" :class="$style.error">
            {{ this.errors.endAt }}
          </div>
        </label>
      </div>
      <editor
        v-model="offer.description"
        tag-name="div"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
          ],
          toolbar:
            'undo redo | styleselect | bold italic underline strikethrough link | \
            | numlist bullist outdent indent | | superscript subscript blockquote | alignleft aligncenter alignright alignjustify | \
          | removeformat | help',
        }"
      />
      <div v-if="this.errors.description" :class="$style.error">
        {{ this.errors.description }}
      </div>
      <label class="label" :class="$style.lableUrl">
        Материалы для скачивания
        <input
          type="url"
          v-model="offer.attachmentLink"
          placeholder="https://...."
          :class="$style.url"
        />
      </label>

      <div v-if="this.errors.attachmentLink" :class="$style.error">
        {{ this.errors.attachmentLink }}
      </div>
      <h5>
        Если ценовая политика не указана, то акция отображается для всех
        пользователей
      </h5>
      <el-select
        v-model="offer.pricePolicies"
        multiple
        filterable
        default-first-option
        placeholder="Выберите ценовые политики"
      >
        <el-option
          v-for="item in pricePoliciesOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div :class="$style.action">
        <Button :type="'submit'">Изменить акцию</Button>
        <div v-if="error" :class="$style.error">
          {{ error }}
        </div>
      </div>
      <Loader :isLoading="isLoading" />
    </form>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import regexp from '@/helpers/regexp.js'
import ImageBulkUploader from '@/components/moleculs/ImageBulkUploader'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import delivery from '@/delivery'
import { formatInputDate } from '@/helpers'
import notifications from '@/mixins/notifications'

export default {
  components: {
    editor: Editor,
    ImageBulkUploader,
    Button,
    Loader,
  },
  mixins: [notifications],
  computed: {
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
  },
  data() {
    return {
      pricePoliciesOptions: [],
      offer: {
        title: '',
        description: '',
        image: '',
        beginningAt: '',
        endAt: '',
        attachmentLink: '',
        pricePolicies: [],
      },
      errors: {
        title: '',
        description: '',
        image: '',
        beginningAt: '',
        endAt: '',
        attachmentLink: '',
      },
      error: '',
      isLoading: false,
    }
  },
  async created() {
    let result

    result = await delivery.AddwineCore.OffersActions.getById(
      this.$route.params.id,
    )

    if (result.error) {
      console.error(result.error)
    } else {
      this.offer.title = result.value.title
      this.offer.description = result.value.description
      this.offer.image = [result.value.image]
      this.offer.beginningAt = formatInputDate(result.value.beginningAt)
      this.offer.endAt = formatInputDate(result.value.endAt)
      this.offer.attachmentLink = result.value.attachmentLink
      this.offer.pricePolicies = result.value.pricePolicies
    }
  },
  mounted() {
    this.getPricesPolicies()
  },
  methods: {
    async getPricesPolicies() {
      const loading = this.$loading({
        lock: true,
      })
      const { value, error } =
        await delivery.AddwineCore.PricePolicyActions.getList({
          limit: 100,
          page: 1,
        })
      loading.close()
      if (!error) {
        this.pricePoliciesOptions = value.data
      }
    },
    clearErros() {
      this.error = ''
      for (const key in this.errors) {
        this.errors[key] = ''
      }
    },
    isFormValid() {
      let isValid = true
      this.clearErros()
      if (!this.offer.title) {
        this.errors.title = 'Введите заголовок'
        isValid = false
      }
      if (!this.offer.description) {
        this.errors.description = 'Добавьте текст'
        isValid = false
      }
      if (!this.offer.image) {
        this.errors.image = 'Добавьте картинку'
        isValid = false
      }
      if (!this.offer.beginningAt) {
        this.errors.beginningAt = 'Добавьте дату начала акции'
        isValid = false
      }
      if (!this.offer.endAt) {
        this.errors.endAt = 'Добавьте дату окончания акции'
        isValid = false
      }
      if (new Date(this.offer.beginningAt) > new Date(this.offer.endAt)) {
        this.errors.beginningAt =
          'Неправильно введена дата: начальная больше, чем конечная'
        isValid = false
      }
      if (
        this.offer.attachmentLink &&
        !regexp.url.test(this.offer.attachmentLink)
      ) {
        this.errors.attachmentLink = 'Введите валидный url'
      }
      if (!isValid) {
        this.error = 'Заполните все поля или введите корркетные данные'
      }

      return isValid
    },
    async submitForm() {
      this.clearErros()
      if (this.isFormValid()) {
        this.isLoading = true
        const result = await delivery.AddwineCore.OffersActions.update(
          this.$route.params.id,
          {
            ...this.offer,
            image: this.offer.image[0],
            beginningAt: new Date(this.offer.beginningAt),
            endAt: new Date(this.offer.endAt),
          },
        )
        if (result.error) {
          this.error = result.error
        } else {
          this.showNotification(`Акция успешно изменена`, 'success')
        }
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';
.container {
  max-width: 58.5rem;
  margin: 0 auto;
  padding: 1rem;

  form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    label {
      text-align: left;
    }
  }
  .title {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
  }
  .imageUploader {
    text-align: left;
    color: $black-gray;
    padding: 0.5rem;
    border: 0.063rem solid $black-gray;
    max-width: 12.5rem;
  }
  .photo {
    width: 100%;
    height: 25rem;
    border: 1px solid $black-gray;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .date {
    display: flex;
    gap: 1rem;
    label {
      color: $black-gray;
    }
  }
  .lableUrl {
    color: $black-gray;
    text-align: left;
  }
  .url {
    width: 100%;
    padding: 0.5rem;
  }
  .error {
    @include errorInscription;
    margin-top: 0.4rem;
  }
}
</style>
